.password-change{
    width: 360px;
    margin: auto; 
    display: flex;
    height:100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* 로그인 이너 */
.password-change-inner{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.password-change-title{
    color: #474747;
    font-size: 2em;
    margin-bottom:15px ;
}
.password-change-inner form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 20px;
}
.password-change-inner form fieldset{
    border: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.password-change-input-id, 
.password-change-input-cf-number,
.password-change-input-cf-email{
    position: relative;
}
.password-change-input-id .bi, 
.password-change-input-cf-email .bi,
.password-change-input-cf-number .bi{
    top: 60%;
    transform: translateY(-60%);
    position: absolute;
    font-size: 2.2em;
    left: 10px;
    transition: 0.5s;
}
.password-change-input-id input[type=text],
.password-change-input-cf-number input[type=text],
.password-change-input-cf-email input[type=email]{
    width: 100%;
    padding: 15px;
    padding-left: 50px;
    border-radius: 5px;
    outline: none;
    border: 1px solid var(--maincolor);
    font-size: 1.3em;
    transition: 0.4s;
}
.password-change-input-cf-number-delevery{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.password-change-input-cf-number-delevery input[type=button]{
    width: 276px;
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--remonblue);
    transition: 0.5s;
    color: white;
    font-size: 1.3em;
    padding: 15px;
}
.password-change-input-cf-number-delevery input[type=button]:hover{
    background-color: var(--royalblue);
    box-shadow: 0 0 10px var(--royalblue);
}
.password-change-input-id input[type=text]::placeholder,
.password-change-input-cf-number input[type=text]::placeholder,
.password-change-input-cf-email input[type=email]::placeholder{
    transition: 0.5s;
}
.password-change-input-id input[type=text]:focus,
.password-change-input-cf-number input[type=text]:focus,
.password-change-input-cf-email input[type=email]:focus
{
    box-shadow: 0 0 10px #2acf7dc4;
}
.password-change-input-id input[type=text]:focus ~ .bi,
.password-change-input-cf-number input[type=text]:focus ~ .bi,
.password-change-input-cf-email input[type=email]:focus ~ .bi{
    color: var(--maincolor);
}
.password-change-input-id input[type=text]:focus::placeholder,
.password-change-input-cf-number input[type=text]:focus::placeholder,
.password-change-input-cf-email input[type=email]:focus::placeholder{
    opacity: 0;
}
.password-change-btn{
    display: block;
    width: 80%;
    margin: auto;
}
.password-change-btn a{
    display: block;
    border-radius: 25px;
    padding: 15px;
    font-size: 1.3em;
    background-color: var(--maincolor);
    color: white;
    transition: 0.45s;
}
.password-change-btn a:hover{
    background-color: var(--forestgreen);
    box-shadow: 0 0 10px var(--forestgreen);
}
.forgot-password{
    cursor: pointer;
    font-size: 1.3em;
}

/* user-find */
.password-change-user-find{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 15px;
}
.password-change-user-find a{
    font-size: 1.2em;
}
.password-change-user-find a:nth-of-type(2){
    position: relative;
}
.password-change-user-find a:nth-of-type(2)::before{
    left: -8px;
}
.password-change-user-find a:nth-of-type(2)::before{
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 10px;
    background-color: var(--dark-gray);
}


