.calender-page{
  margin-top: 20px;
}
.Month{
  margin-bottom: 20px;
  color: #2ACF7D;
  font-size: 30px;
}

.calendar{
  background: #ffffff;
  width: 350px;
  padding: 1rem;
  padding-top:  2rem;
  border-radius: 35px;
  border: solid 8px #2ACF7D;
  margin: auto;
  margin-bottom: 20px;
 

}
.calendar ul.date {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr ; 
  margin-bottom: 15px;
}
.daysArray {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  margin-bottom: 15px;
}
.days {
  background-color: #2ACF7D;
  color: white;
  border-radius: 50%;
  font-size: 1.1em; /* 1em으로 조정 */
  width: 40px; /* 40px로 조정 */
  height: 40px; /* 40px로 조정 */
  text-align: center;
  line-height: 40px;
}

 .days:first-child{
  background-color: crimson;
 }
 .days:last-child{
  background-color: royalblue;
 }
 
.calendar ul.date li{
  BORDER: 0.1px solid #2ACF7D;
 padding: 0.5em;/*반칸*/
 color: #2ACF7D;
 
}
.calendar ul.date li:hover {
  background-color: #2ACF7D; /* 호버 시 배경색 변경 */
  color: #FFF; /* 호버 시 글꼴 색상 변경 */
  /* 다른 원하는 스타일 속성 추가 */
}


.calendar header{
  display: flex;
  justify-content: center;
}
.calendar header h2{
    line-height: 40px;
    margin: 0;
    font-size: 40px;
    margin-right: 1rem;
}
/* App.css */
 .next {
  order: 3;/*배치하고싶은순서*/
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 20px solid #2ACF7D;
  background-color: transparent;
  cursor: pointer;
  border-right: transparent;
}
  .prev{
    
      order: 1; /*배치하고싶은순서*/
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 20px solid #2ACF7D; /* border-right를 추가하여 변의 방향을 변경 */
      /* background-color: transparent; */
      cursor: pointer;
      border-left: transparent;
      background-color: transparent;
      
      
    }
    
    
.Date-march{
  color: #2ACF7D;
  font-size: 2.2em
  
  


}
.calender-page-title{
  display: flex;/*특정 요소에 적용하면 해당 요소의 자식 요소들에 대해 Flexbox 레이아웃이 적용 행 또는 열로 배치하는 방법*/
  justify-content: center;/* Flexbox 레이아웃에서 Flex 컨테이너의 주축(main axis) 방향에 있는 아이템들을 가운데로 정렬*/
  gap: 20px; /* Flexbox 컨테이너의 아이템들 사이에 30픽셀의 간격이 생깁*/
  color: #2ACF7D;
  align-items: center;/*는 Flexbox 레이아웃에서 Flex 컨테이너의 교차축(cross axis) 방향에 있는 아이템들을 가운데로 정렬*/
  margin-bottom: 10px;
}
.Date-march{
  order: 2;   /*배치하고싶은순서*/
  
}



.container1{
  border: solid 1px #2ACF7D;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;  
  padding-bottom: 20px;
}
.schedule {
  color: #ffffff;
  border: none;
  background-color: #2ACF7D;
  border-radius: 5px;
  padding: 10px 20px; /* 버튼 내부 여백 조정 */
  font-size: 1em; /* 버튼 텍스트 크기 조정 */
  cursor: pointer;/*손가락 표시   */
}

.delete-btn{
  color: #ffffff;
  outline: none;
  cursor: pointer;
  background-color: #2ACF7D;
  border-radius: 100px;
  margin-left: 5px;
  font-size: 7px;
  border: 1px solid transparent;
  /* transform: translateY(1px); */
}
  
.date li{
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
  cursor: pointer;/*손가락 표시   */
 

}
.events-list{
  display: flex;
  gap: 5px;
  flex-direction: column;
  font-size: 12px;
}

.events-list-div{
  display: flex;
  gap: 8px;
  align-items: center;
}
.Date-march span {
  font-size: 27px;

}
.selected-day {
  color: red;
  background-color: green;
  font-size: 13px;
}
.calendar-header{
  width: auto;
}