[class *= place]{
  width: 100%;
  margin-top: 20px;
}
[class *= input-kinds]{
  width: 100%;
  padding: 10px ;
  border-radius: 5px ;
  outline: none ;
  border: 1px solid var(--maincolor);
  font-size: 1.3em ;
  padding-left: 55px;
}
.subject{
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subject p{
  font-size: 1.6em;
}
.input-kinds::placeholder{
  transition: 0.5s;
}
.input-kinds:focus::placeholder{
  opacity: 0;
}
.input-kinds:focus{
  box-shadow: 0 0 10px #2acf7d96;
}
.change-info{
  /* border: 1px solid red; */
  width: 360px;
  margin: auto;
}
.change-info h1{
  font-size: 3em;
  text-align: center;
  padding-top: 35px;
}
.change-info-inner{
  width: inherit;
  padding: 20px;
}
.change-info-place{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.change-info-place span{
  display: block;
  width: 100%;
  margin-top: 15px;
  font-size: 1.1rem;
  color:  red;
  text-align: center;
}
.id-subject, .email-subject, .nick-subject{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.id-subject input[type=button], .email-subject input[type=button], .nick-name-place input[type=button], .cfnumber-subject input[type=button]{
  cursor: pointer;
  background-color: var(--maincolor);
  color: white;
  font-size: 1.4em;
  padding: 5px 10px;
  border-radius: 15px;
}
.input-box {
  position: relative;
}
.gender-check-place{
  display: flex;
  gap: 15px;
}
label[for=male],label[for=female]{
  display: flex;
  font-size: 1.5em;
  cursor: pointer;
}
.gender-input{
  margin-right: 10px;
  appearance: none;
  vertical-align: middle;
  border: 2px solid var(--maincolor);
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
}
.gender-input:checked{
  border: 5px solid var(--forestgreen);
}
.user-profile{
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-profile .user-profile-basic{
  width: 120px;
  height: 120px;
  border: 1px solid transparent;
  background-color: var(--mintcolor);
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
}
.upload-profile-img{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  flex-direction: column;
  gap: 15px;
}
.upload-profile-img input[type="file"]{
  display: none;
}
.upload-profile-custom-file-input{
  background-color: var(--remonblue);
  color: white;
  padding: 10px 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.4em;
  transition: 0.4s;
  position: relative;
}
.upload-profile-custom-file-input:hover{
  background-color: var(--royalblue);
  box-shadow:  0 0 5px var(--royalblue);
}
.upload-profile-custom-file-input .bi{
  position: absolute;
  left: 8px;
  top: 60%;
  transform: translateY(-60%);
  font-size: 1.1em;
  color: white;
}
.user-profile-input{
  width: 90%;
  text-align: center;
}
.input-box .bi{
  /* 아이콘 전체 조정 */
  position: absolute;
  left: 10px;
  font-size: 2.2em;
  transition: 0.4s;
  top: 60%;
  transform: translateY(-60%);
}
.input-kinds:focus ~ .bi{
  color: var(--maincolor);
} 
.introduce-place textarea{
  width: 100%;
  border: 1px solid  var(--maincolor);
  border-radius: 5px;
  transition: 0.5s;
  padding: 15px;
  font-size: 1.2em;
  letter-spacing: 2px;
}
.introduce-place textarea:focus{
  box-shadow: 0 0 10px #2acf7d96;
}
.cf-number-send-place{}
.cf-number-send-place input[type=button]{
  cursor: pointer;
  width: 100%;
  padding: 15px;
  font-size: 1.4em;
  color: white;
  background-color: var(--remonblue);
  border-radius: 35px;
  transition: 0.4s;
}
.cf-number-send-place input[type=button]:hover{
  background-color: royalblue;
  box-shadow: 0 0 10px royalblue;
}
.toggle-eye{
  cursor: pointer;
  width: 10px;
}

/* 각 영역별 아이콘 정렬 (각 아이콘 크기 달라서 각자 조정) */
.input-pw .bi{
  top: 55%;
}
.input-cfnumber .bi{
  font-size: 2em;
  top: 65%;
}

/* change-info-complete-btn */
.change-info-complete-btn{
  margin-top: 10px;
}
.change-info-complete-btn .change-info-btn{
  text-align: center;
  display: block;
  border-radius: 35px;
  background-color: var(--maincolor);
  color: white;
  font-size: 1.65em;
  padding:  10px 20px;
  transition: 0.4s;
}
.change-info-complete-btn .change-info-btn:hover{
  background-color: var(--forestgreen);
  box-shadow: 0 0 10px var(--forestgreen);
}
