.password-change-complete{
    width: 360px;
    margin: auto; 
    display: flex;
    height:100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* password-change-complete-inner */
.password-change-complete-inner{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.password-change-complete-title{
    color: #474747;
    font-size: 2em;
    margin-bottom:15px ;
}
.password-change-complete-inner form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 20px;
}
.password-change-complete-inner form fieldset{
    border: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.input-pw, .input-pw-check{
    position: relative;
}
.input-pw-text,.input-pw-check-text{
    width: 80%;
    color: red;
    margin: 0;
    padding: 0;
    transition: 0.3s;
    font-size: 1.05em;
}
.input-pw .bi, .input-pw-check .bi{
    top: 50%;
    transform: translateY(-45%);
    position: absolute;
    font-size: 2.2em;
    left: 10px;
    transition: 0.5s;
}

.input-pw input[type=password],.input-pw-check input[type=password],.input-pw input[type=text]{
    width: 100%;
    padding: .9375rem;
    padding-left: 50px;
    border-radius: .3125rem;
    outline: none;
    border: .0625rem solid var(--maincolor);
    font-size: 1.3em;
}

.input-pw input[type=password]::placeholder,.input-pw-check input[type=password]::placeholder,.input-pw input[type=text]::placeholder{
    transition: 0.5s;
}
.input-pw input[type=password]:focus::placeholder,.input-pw-check input[type=password]:focus::placeholder,.input-pw input[type=text]:focus::placeholder{
    opacity: 0;
}
.input-pw input[type=password]:focus,.input-pw-check input[type=password]:focus,.input-pw input[type=text]:focus{
    box-shadow: 0 0 10px #2acf7dc4;
}
.input-pw input[type=password]:focus ~ .bi,.input-pw-check input[type=password]:focus ~ .bi,.input-pw input[type=text]:focus ~ .bi{
    color: var(--maincolor);
}

.password-change-complete-btn{
    display: block;
    width: 80%;
    margin: auto;
}
.password-change-complete-btn a{
    display: block;
    border-radius: 25px;
    padding: 15px;
    font-size: 1.3em;
    background-color: var(--maincolor);
    color: white;
    transition: 0.45s;
}
.password-change-complete-btn a:hover{
    background-color: var(--forestgreen);
    box-shadow: 0 0 10px var(--forestgreen);
}
/* user-find */
.password-change-complete-user-find{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 15px;
}
.password-change-complete-user-find a{
    font-size: 1.2em;
}
.password-change-complete-user-find a:nth-of-type(2){
    position: relative;
}
.password-change-complete-user-find a:nth-of-type(2)::before{
    left: -8px;
}
.password-change-complete-user-find a:nth-of-type(2)::before{
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 10px;
    background-color: var(--dark-gray);
}


