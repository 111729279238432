* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
.board_wrap {
  width: 1440px;
  margin: 100px auto;
}
.board_title strong {
  font-size: 3rem;
}
.board_title p {
  margin-top: 5px;
  font-size: 1.4rem;
}
.bt_wrap {
  margin-top: 30px;
  font-size: 0;
  text-align: center;
}
.bt_wrap a {
  padding: 10px 20px;
  font-size: 1px;
  background-color: #2acf7d; /* 변경된 배경색 */
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  transition: 0.4s;
  font-weight: bold;
  font-size: 20px;
}

.bt_wrap a:hover {
  background-color: #199b5a; /* 변경된 배경색 */
  color: #000000;
}

.bt_wrap a .on {
  background-color: #000;
  color: #fff;
}
.board_list {
  width: 100%;
  border-top: 1px solid #2acf7d;
  clear: both;
}
.board_list > div {
  border-bottom: 1px solid #ddd;
  font-size: 0px;
}
.board_list > div.top {
  border-bottom: 1px solid #999;
  display: flex;
}

.board_list > div > div {
  display: inline-block;
  padding: 15px 0;
  text-align: center;
  font-size: 15px;
}
.board_list > div .top > div {
  font-weight: 600;
}

.tab-menu {
  flex: 1;
  font-size: 0.8em;
  background-color: #2acf7d;
  color: #ffffff;
  font-weight: bold;
}



/*아래는 리스트*/
.master-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.master-list-item {
  flex: 5px;
}

.ReportedUser {
  padding: 10px 20px;
  font-size: 1px;
  background-color: #2acf7d; /* 변경된 배경색 */
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  transition: 0.4s;
  font-weight: bold;
  font-size: 12px;
}
.ReportedUser:hover {
  background-color: #199b5a; /* 변경된 배경색 */
  color: #000000;
}

.UserBoard{
  padding: 10px 20px;
  font-size: 1px;
  background-color: #2acf7d; /* 변경된 배경색 */
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  transition:0.4s;
  margin: 1px;
  font-weight: bold;
  font-size: 12px;
}
.UserBoard:hover {
  background-color: #199b5a; /* 변경된 배경색 */
  color: #000000;
}n
/* 마우스 커서가 버튼 위로 올라갔을 때 스타일 변경 */
.ReportedUser:hover {
  background-color: #199b5a; /* 변경된 배경색 */
  color: #000000;
}
.SuspendedUser {
  padding: 10px 20px;
  font-size: 1px;
  background-color: #2acf7d; /* 변경된 배경색 */
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  transition:0.4s;
  margin: 1px;
  font-weight: bold;
  font-size: 12px;
}
.SuspendedUser:hover {
  background-color: #199b5a; /* 변경된 배경색 */
  color: #000000;
}
.ManageUsers {
  padding: 10px 20px;
  font-size: 1px;
  background-color: #2acf7d; /* 변경된 배경색 */
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  transition:0.4s;
  font-weight: bold;
  font-size: 12px;
}
.ManageUsers:hover {
  background-color: #199b5a; /* 변경된 배경색 */
  color: #000000;
}
.board_page a {
  display: inline-block;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  vertical-align: middle;
  border: 1px solid #ddd;
  border-left: 0;
  line-height: 100%;
}
.board_page a.bt {
  padding-top: 10px;
  font-size: 1.2rem;
  letter-spacing: 1px;
}
.board_page a.num {
  padding-top: 9px;
  font-size: 1.4rem;
}
.board_page a.num.on {
  border-color: #000;
  background: #000;
  color: #fff;
}
.board_page a:first-child {
  border-left: 1px solid #ddd;
}
.SuspendButton,.UnsuspendButton,.delteButton,.recoveryButton  {
  width: 55px;
  padding: 10px;
  font-size: 1px;
  color: white;
  border: none;
  cursor: pointer;
  transition:0.4s;
  font-weight: bold;
  border-radius: 10px;
}
.SuspendButton{
  background-color: red;
}
.SuspendButton:hover {
  background-color: crimson;
  box-shadow: 0 0 8px crimson;
}

.UnsuspendButton,.recoveryButton {
  background-color: var(--remonblue);
}
.UnsuspendButton:hover,
.recoveryButton:hover {
  background-color: royalblue;
  box-shadow: 0 0 8px royalblue;
}
.modalButton{
  padding: 9px 12px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  border-radius: 10px;
  border-radius: 5px;
  transition: 0.4s;
  font-size: 0.8em;
}

.modalButton1 {
  background-color: #ff0000;
}

.modalButton1:hover {
  background-color: crimson;
  box-shadow: 0 0 8px crimson;
}

.modalButton2 {
  background-color: var(--remonblue);
}

.modalButton2:hover {
  background-color: royalblue;
  box-shadow: 0 0 8px royalblue;
}
.input-stop-box{
  display: flex;
  align-items: center;
  gap: 5px;
}
.day-stop{
  font-size: 1.2em;
}
.inputNumber {
  width: 70px;
  height: 40px;
  border: solid 2px black;
  border-radius: 5px;
  text-align: center;
  margin-right: 5px;
  font-size: 1em;
  
}
.daySuspension{
  border: solid 2px var(--maincolor);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2.5em;
  padding: 20px;
  width: 300px;
  height: 300px;
  gap: 10px;

}
.bottomSection{
  display: flex;
  gap: 15px;
  margin-top: 10px;
}
.popup_overlay{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}  
.popup_content{
  background-color: #ffffff;
  width: 450px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px #ccc;
}
.stop{
  font-size: 3em;
  margin-bottom: 10px;
}


.top{
  display: flex;
  justify-content: space-around;
}
.top li{
  /* flex: 1; */
  display: flex;
  font-size: 1.3em;
  align-items: center;
  justify-content: center;
}
.master-list{
  display: flex;
  justify-content: space-around;
  margin-top: 5px;

}
.master-list li{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  
}
.master-list li div{
  display: flex;
  justify-content: center;
  flex: 1;
}
.master-btns{
  display: flex;
  gap: 3px;
}
.master-btn button{
  padding: 0;
}
.SearchFunction{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  font-size: 15px;
}

/* master-select-search */
.master-select-search{
  display: flex;
  float: right;
  gap: 15px;
  margin-bottom: 10px;

}
/* master-search */
.master-search{
  display: flex;
  align-items: center;
  gap: 15px;
}
.master-search-box{
  font-size: 1.2em;
  border-bottom: 2px solid var(--maincolor);
  width: 250px;
}
.master-search-box::placeholder{
  transition: 0.4s;
}
.master-search-box:focus::placeholder{
  opacity: 0;
}
.master-search-btn{
  font-size: 1.1em;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: var(--maincolor);
  color:white;
}
/* master-select-box */
.master-select-box{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.master-select-box-span {
  font-size: 1.1em;
}
.master-select-options{
  width: 150px;
  font-size: 1.1em;
  border: none;
  /* border-bottom: 2px solid var(--maincolor); */
}
.category-btn{
  width: 150px;
  font-size: 1.15em;
}
.ban-btn{
   font-size: 1em;
}
.delteButton{
  background-color: red;
  transition: 0.3s;
}
.delteButton:hover{
  background-color: crimson;
  box-shadow: 0 0 5px crimson;
}

