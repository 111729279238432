
.slide-area {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-top: 20px;

  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: opacity 0.5s;
  }

  .slide.active {
    opacity: 1;
  }
  .bannerTitleBox{
    display: flex;
    flex-direction: column;
    position: relative;
    float: right;
    top: 70%;
    z-index: 50;
    color: white;
    text-shadow: 1px 1px 2px #343434;
    .bannerTitle{
      font-size: 5rem;
      margin-right: 10px;
      padding-right: 10px;
      text-shadow: 1px 1px 5px rgba($color: #343434, $alpha: 1.0);
    }

    .bannerToVolunteer{
      height: 50px;
      font-size: 1.5rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 10px 0px;
      padding-right: 20px;
      a {
        display: flex;
        align-items: center;
        color: white;
      }
    }
  }
  
}